import { Flex } from '@chakra-ui/react';
import { Button } from '@ours/web-utils';
import type { FC } from 'react';
import { memo } from 'react';

interface IDraftFooterProps {
  isLoading: boolean;
  onSaveDraft: () => void;
  text: string;
}

export const DraftFooter: FC<IDraftFooterProps> = memo(({ isLoading, onSaveDraft, text }) => {
  return (
    <Flex justifyContent="end" px="4" py="4" w="full">
      <Button as="button" isLoading={isLoading} onClick={onSaveDraft} size="sm" type="button">
        {text}
      </Button>
    </Flex>
  );
});
