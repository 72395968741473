import { Box } from '@chakra-ui/react';
import { unexpected } from '@ours/utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { memo } from 'react';

import type { MenuItemLink, OnClose } from './types';

export interface SideMenuItemLinkProps {
  item: MenuItemLink;
  onClose: OnClose;
}

export const SideMenuItemLink: FC<SideMenuItemLinkProps> = memo(({ item, onClose }) => {
  const { hidden, href, items, text } = item;
  const { pathname } = useRouter();
  const isActive = pathname === href;

  if (items?.length) {
    throw unexpected({ name: 'Implement Double Nesting' });
  }

  if (hidden) {
    return null;
  }

  return (
    <Box
      _hover={{ textDecoration: href ? 'underline' : undefined }}
      color={isActive ? 'brand.blue' : undefined}
      onClick={onClose}
    >
      {href ? <Link href={href}>{text}</Link> : text}
    </Box>
  );
});
