import { Flex } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import { useUploadProgresses } from '@ours/web-utils';
import type { FC } from 'react';
import { memo } from 'react';

import { DisplayUploadProgress } from './lib/DisplayUploadProgress';

export const ProgressDisplayContainer: FC = memo(() => {
  const uploads = useUploadProgresses();

  if (!uploads.length) {
    return null;
  }

  return (
    <Flex
      bg="white"
      border={Border}
      bottom="0"
      flexDir="column"
      left="0"
      pos="fixed"
      px="400"
      py="200"
      zIndex="10000"
    >
      {uploads.map((upload) => (
        <DisplayUploadProgress key={upload.key} upload={upload} />
      ))}
    </Flex>
  );
});
