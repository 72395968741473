import type { FC } from 'react';
import { memo } from 'react';

import { SideMenuItemButton } from './SideMenuItemButton';
import { SideMenuItemLink } from './SideMenuItemLink';
import type { OnClose, SidebarMenuItem } from './types';

export interface SideMenuItemProps {
  item: SidebarMenuItem;
  onClose: OnClose;
}
const lookup: Record<SidebarMenuItem['kind'], FC<{ item: any; onClose: OnClose }>> = {
  button: SideMenuItemButton,
  link: SideMenuItemLink,
};
export const SideMenuItem: FC<SideMenuItemProps> = memo(({ item, onClose }) => {
  const Comp = lookup[item.kind];

  return <Comp item={item} onClose={onClose} />;
});
