import { Box } from '@chakra-ui/react';
import { unexpected } from '@ours/utils';
import { useEvent } from '@ours/web-utils';
import type { FC, MouseEventHandler } from 'react';
import { memo } from 'react';

import type { MenuItemButton, OnClose } from './types';

export interface SideMenuItemButtonProps {
  item: MenuItemButton;
  onClose: OnClose;
}

export const SideMenuItemButton: FC<SideMenuItemButtonProps> = memo(({ item, onClose }) => {
  const { hidden, items, onClick, text } = item;

  const onClickLocal: MouseEventHandler<any> = useEvent((e) => {
    onClose(e);
    onClick();
  });

  if (items?.length) {
    throw unexpected({ name: 'Implement Double Nesting' });
  }

  if (hidden) {
    return null;
  }

  return (
    <Box _hover={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={onClickLocal}>
      {text}
    </Box>
  );
});
