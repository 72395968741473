import { _useStore } from '../../store/_useStore';
import type { StoreState } from '../../store/types';

export const selectIsShowingPII = (s: StoreState) => {
  return s.adminMachine.state.context.ui.isShowingPII;
};

export const useIsShowingPII = () => {
  return _useStore(selectIsShowingPII);
};
