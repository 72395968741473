import { useEvent } from '../../../hooks/useEvent';
import { _useStore } from '../../store/_useStore';

export const useAdminMachineActions = () => {
  const send = _useStore((s) => s.adminMachine.send);

  const onShowPII = useEvent((durationMS: number) => {
    send({ durationMS, type: 'OnShowPII' });
  });
  const onShowPIIFor10S = useEvent(() => {
    send({ durationMS: 10 * 1000, type: 'OnShowPII' });
  });

  const onHidePII = useEvent(() => {
    send({ type: 'OnHidePII' });
  });

  return { onHidePII, onShowPII, onShowPIIFor10S };
};
