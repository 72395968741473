import { Box, Drawer, DrawerContent, Flex, useBoolean } from '@chakra-ui/react';
import { useEvent, useMetaTitle } from '@ours/web-utils';
import useHover from '@react-hook/hover';
import type { FC, MouseEventHandler, ReactNode } from 'react';
import { memo, useEffect, useRef } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';

import { ProgressDisplayContainer } from '../ProgressDisplay/ProgressDisplayContainer';

import { SidebarMenu } from './SidebarMenu/SidebarMenu';
import { DraftItemsHolder } from './lib/DraftItemsHolder';

export const SidebarLayoutMenuHeight = 41;
export const SidebarLayout: FC<{ children: ReactNode }> = memo(({ children }) => {
  const [isOpen, isOpenCtl] = useBoolean(false);
  const title = useMetaTitle();
  const onClose: MouseEventHandler<any> = useEvent((e) => {
    if (!e.metaKey) {
      isOpenCtl.off();
    }
  });
  const ref = useRef<HTMLDivElement>(null);
  const isHovering = useHover(ref, { enterDelay: 150 });

  useEffect(() => {
    if (isHovering) {
      isOpenCtl.on();
    }
  }, [isHovering, isOpenCtl]);

  return (
    <Flex flexDir="column" flexGrow={1} h="full" overflow="hidden" pos="relative" w="full">
      <DraftItemsHolder />

      <Drawer isOpen={isOpen} onClose={isOpenCtl.off} placement="left" size="xs" trapFocus={false}>
        <DrawerContent bottom="unset!important" maxW="280px">
          <SidebarMenu onClose={onClose} />
        </DrawerContent>
      </Drawer>

      <Box bottom="0" left="0" pos="absolute" ref={ref} top="0" w="20px" zIndex={1} />

      <Flex
        alignItems="center"
        bg="brand.beige"
        h={SidebarLayoutMenuHeight + 'px'}
        justifyContent="space-between"
        p="100"
        pos={{ base: 'relative', md: 'sticky' }}
        textStyle="sm"
        top="0"
        w="full"
        zIndex={1}
      >
        <Flex gap="100">
          <Box onMouseOver={isOpenCtl.on}>
            <GiHamburgerMenu />
          </Box>
          {title}
        </Flex>
      </Flex>

      <Flex bg="white" flexDir="column" flexGrow={1} h="full" pos="relative" w="full">
        {children}
      </Flex>

      <ProgressDisplayContainer />
    </Flex>
  );
});
