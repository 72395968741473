import { _useStore } from '../../store/_useStore';
import type { StoreState } from '../../store/types';

const select = (s: StoreState) => {
  return Object.entries(s.admin.progress.items)
    .filter(([, value]) => value.type === 'upload')
    .map(([key, val]) => ({ key, ...val }));
};

export const useUploadProgresses = () => {
  return _useStore(select);
};
