import { useCallback } from 'react';

import { _useStore } from '../../store/_useStore';
import type { StoreState } from '../../store/types';

export const useAdminDraftItem = (id: string) => {
  const select = useCallback(
    (s: StoreState) => {
      return s.admin.drafts.draftsById[id];
    },
    [id]
  );

  return _useStore(select);
};
