import { _useStore } from '../../store/_useStore';

export const useAdminDraftActions = () => {
  const addNewDraft = _useStore((s) => s.admin.drafts.addNewDraft);
  const removeDraft = _useStore((s) => s.admin.drafts.removeDraft);
  const setDraftItemValue = _useStore((s) => s.admin.drafts.setDraftItemValue);
  const toggleDraftOpen = _useStore((s) => s.admin.drafts.toggleDraftOpen);

  return {
    addNewDraft,
    removeDraft,
    setDraftItemValue,
    toggleDraftOpen,
  };
};
