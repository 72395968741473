/* eslint-disable react/no-unused-prop-types */
import type { SelectProps } from '@chakra-ui/react';
import { Select as ChakraSelect, Skeleton } from '@chakra-ui/react';
import type { LabelValue } from '@ours/types';
import { Colors } from '@ours/utils';
import { forwardRef, memo, useMemo } from 'react';

import type { Size } from './sizeLookup';
import { sizeLookup } from './sizeLookup';

const variants = {
  base: {
    bg: 'brand.beige',
    borderColor: 'black',
    focusColor: Colors.brand.blue,
    invalidColor: Colors.ui.error,
  },
  white: {
    bg: 'white',
    borderColor: 'black',
    focusColor: Colors.brand.blue,
    invalidColor: Colors.ui.error,
  },
} as const;

export interface OursSelectProps extends SelectProps {
  emptyText?: string;
  loading?: boolean;
  omitEmpty?: boolean;
  options: readonly LabelValue[];
  size?: Size;
  variant?: keyof typeof variants;
}

export const Select = memo(
  forwardRef<any, OursSelectProps>(
    ({ emptyText, loading, omitEmpty, options, variant = 'base', ...props }, ref) => {
      const { bg, borderColor, focusColor, invalidColor } = variants[variant];
      const { h } = sizeLookup[props.size || 'md'];

      const colors = useMemo(() => {
        return {
          _focus: { borderColor: focusColor },
          _hover: { borderColor },
          _invalid: { borderColor: invalidColor },
        };
      }, [borderColor, focusColor, invalidColor]);

      if (loading) {
        return <Skeleton h={h} />;
      }

      return (
        <ChakraSelect
          {...props}
          _focus={colors._focus}
          _hover={colors._hover}
          _invalid={colors._invalid}
          bg={bg}
          borderBottomLeftRadius="8px"
          borderBottomRightRadius="0px"
          borderColor={borderColor}
          borderTopLeftRadius="0px"
          borderTopRightRadius="8px"
          borderWidth={{ base: '1px', md: '2px' }}
          h={h}
          ref={ref}
        >
          {omitEmpty ? null : <option value="">{emptyText || 'Select an option'}</option>}
          {options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </ChakraSelect>
      );
    }
  )
);
