import { _useStore } from '../../store/_useStore';
import type { StoreState } from '../../store/types';

const select = (s: StoreState) => {
  return Object.keys(s.admin.drafts.draftsById);
};

export const useAdminDraftItems = () => {
  return _useStore(select);
};
