/* eslint-disable max-lines-per-function */
import { Box, Divider } from '@chakra-ui/react';
import { CustomAppHeaders, assertIsUUID, unexpected, uuid } from '@ours/utils';
import {
  useAdminDraftActions,
  useAdminDraftItem,
  useAsyncEvent,
  useCreateNoteMutation,
  useEvent,
  useUserId,
} from '@ours/web-utils';
import type { FC } from 'react';
import { memo } from 'react';

import { FindMembershipWithNotesDocument } from '../../../generated/custom-hooks';

import { DraftFooter } from './DraftFooter';
import { DraftHeader } from './DraftHeader';
import { DraftNote } from './DraftNote';

interface DraftItemProps {
  id: string;
}

export const DraftItem: FC<DraftItemProps> = memo(({ id }) => {
  const item = useAdminDraftItem(id);
  const userId = useUserId();
  const { removeDraft } = useAdminDraftActions();
  if (!item) {
    throw unexpected({ name: 'ItemIsUndefinedInDraftItem' });
  }

  const [addNote] = useCreateNoteMutation();

  const onClose = useEvent(() => {
    removeDraft(id);
  });

  const [onSaveDraft, isLoading] = useAsyncEvent(async () => {
    assertIsUUID(userId);
    const noteVars = {
      content: item.value,
      entityId: item.entityId,
      entityKind: item.entityKind,
      id: uuid(),
    };

    await addNote({
      awaitRefetchQueries: true,
      context: { headers: { [CustomAppHeaders.waf]: 'AZS311=d' } },
      refetchQueries: () => [
        { query: FindMembershipWithNotesDocument, variables: { id: item.entityId } },
      ],
      variables: { note: noteVars },
    });
    onClose();
  });

  if (!item.isOpen) {
    return (
      <Box minW="260px">
        <DraftHeader id={id} isOpen={item.isOpen} title={`New ${item.kind}`} />
      </Box>
    );
  }

  if (item.kind === 'note') {
    return (
      <Box bg="white" border="1px solid gray.100" maxW="full" shadow="lg" w="580px">
        <DraftHeader id={id} isOpen={item.isOpen} title={`New ${item.kind}`} />
        <DraftNote id={id} value={item.value} />
        <Divider />
        <DraftFooter isLoading={isLoading} onSaveDraft={onSaveDraft} text={`Save ${item.kind}`} />
      </Box>
    );
  }

  throw unexpected({ name: 'DraftItemUnknownCase' });
});
