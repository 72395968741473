import {
  isAdmin,
  isAdminCreator,
  isAdminGuide,
  isAdminGuideSupervisor,
  isAdminSupervisor,
  isClinicalAdmin,
  isClinicalRole,
  isCreator,
  isCustomer,
  isGuide,
  isInternalRole,
  isSupervisor,
} from '@ours/utils';
import { useMemo } from 'react';

import { useUserRole } from '../../state/appMachine/selectors/useUserRole';

export const useUserCan = () => {
  const role = useUserRole();

  return useMemo(
    () => ({
      isAdmin: isAdmin(role),
      isAdminCreator: isAdminCreator(role),
      isAdminCreatorSupervisor: isAdminCreator(role) || isSupervisor(role),
      isAdminGuide: isAdminGuide(role),
      isAdminGuideSupervisor: isAdminGuideSupervisor(role),
      isAdminSupervisor: isAdminSupervisor(role),
      isClinicalAdmin: isClinicalAdmin(role),
      isClinicalRole: isClinicalRole(role),
      isCreator: isCreator(role),
      isCustomer: isCustomer(role),
      isGuide: isGuide(role),
      isInternal: isInternalRole(role),
      isSupervisor: isSupervisor(role),
    }),
    [role]
  );
};
