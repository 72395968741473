import { Box, Flex } from '@chakra-ui/react';
import { useAdminDraftItems } from '@ours/web-utils';
import type { FC } from 'react';
import { memo } from 'react';

import { DraftItem } from './DraftItem';

export const DraftItemsHolder: FC = memo(() => {
  const items = useAdminDraftItems();

  return (
    <Box
      bottom="0"
      maxH="100vh"
      maxW="full"
      overflowY="scroll"
      position="fixed"
      right={{ base: '0', md: '16px' }}
      zIndex={2}
    >
      <Flex gap="2" position="relative">
        {items.map((id) => (
          <DraftItem id={id} key={id} />
        ))}
      </Flex>
    </Box>
  );
});
