import { Box } from '@chakra-ui/react';
import { useAdminDraftActions, useEvent } from '@ours/web-utils';
import type { FC } from 'react';
import { memo } from 'react';

import { RichTextEditor } from './RichTextEditor';

interface IDraftNote {
  id: string;
  value: string;
}

export const DraftNote: FC<IDraftNote> = memo(({ id, value }) => {
  const { setDraftItemValue } = useAdminDraftActions();
  const onChange = useEvent((event: any) => {
    setDraftItemValue({ id, value: event });
  });

  return (
    <Box maxH="80vh" overflowY="scroll" px="100">
      <RichTextEditor
        onChange={onChange}
        placeholder="Start typing to leave a note..."
        value={value}
      />
    </Box>
  );
});
