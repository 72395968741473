import { Box, Flex, IconButton } from '@chakra-ui/react';
import { useAdminDraftActions, useEvent } from '@ours/web-utils';
import type { FC } from 'react';
import { memo } from 'react';
import { MdClose, MdMinimize } from 'react-icons/md';

interface IDraftHeaderProps {
  id: string;
  isOpen: boolean;
  title: string;
}

export const DraftHeader: FC<IDraftHeaderProps> = memo(({ id, isOpen, title }) => {
  const { removeDraft, toggleDraftOpen } = useAdminDraftActions();

  const onMinimize = useEvent(() => {
    toggleDraftOpen(id);
  });

  const onClose = useEvent(() => {
    removeDraft(id);
  });

  return (
    <Flex
      alignItems="center"
      bg="#444444"
      color="white"
      cursor="pointer"
      justifyContent="space-between"
      onClick={onMinimize}
      px="3"
      py="3"
      roundedTop="md"
    >
      <Box>{title}</Box>
      <Flex alignItems="center" gap="0">
        <IconButton
          _hover={{ bg: 'whiteAlpha.700' }}
          aria-label="Minimize"
          bg="transparent"
          onClick={onMinimize}
          size="xs"
          type="button"
        >
          <MdMinimize transform={isOpen ? '' : 'rotate(180)'} />
        </IconButton>
        <IconButton
          _hover={{ bg: 'whiteAlpha.700' }}
          aria-label="Close"
          bg="transparent"
          onClick={onClose}
          size="xs"
          type="button"
        >
          <MdClose />
        </IconButton>
      </Flex>
    </Flex>
  );
});
