import dynamic from 'next/dynamic';

// eslint-disable-next-line react-memo/require-memo
export const RichTextEditor = dynamic(() => import('@mantine/rte'), {
  // Render anything as fallback on server, e.g. loader or html content without editor
  loading: () => null,

  // Disable during server side rendering
  ssr: false,
});
