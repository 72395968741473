import { Box, Flex } from '@chakra-ui/react';
import { useInputState } from '@mantine/hooks';
import { TimeConstants } from '@ours/utils';
import { Button, Select, useAdminMachineActions, useEvent, useIsShowingPII } from '@ours/web-utils';
import type { FC, MouseEventHandler } from 'react';
import { memo } from 'react';

import type { OnClose } from './types';

export interface PiiModeSwitchProps {
  onClose: OnClose;
}

const prefix = 'PII for ';
const options = [
  { label: prefix + '1 min', val: TimeConstants.MILLISECONDS_IN_MINUTE, value: 'oneMinute' },
  { label: prefix + '5 mins', val: 5 * TimeConstants.MILLISECONDS_IN_MINUTE, value: 'fiveMinute' },
  { label: prefix + '10 mins', val: 10 * TimeConstants.MILLISECONDS_IN_MINUTE, value: 'tenMinute' },
] as const;

export const PiiModeSwitch: FC<PiiModeSwitchProps> = memo(({ onClose }) => {
  const [val, setVal] = useInputState(options[0].value);
  const { onHidePII, onShowPII } = useAdminMachineActions();
  const isShowingPII = useIsShowingPII();
  const onToggle: MouseEventHandler<any> = useEvent((e) => {
    onClose(e);

    if (isShowingPII) {
      onHidePII();
    } else {
      onShowPII(options.find((o) => o.value === val)?.val || 1000);
    }
  });

  if (isShowingPII) {
    return (
      <Button as="button" onClick={onToggle} size="smFullW" type="button" variant="beige">
        Hide PII
      </Button>
    );
  }

  return (
    <Flex gap="80" w="full">
      <Box w="full">
        <Select omitEmpty onChange={setVal} options={options} size="xs" value={val} />
      </Box>
      <Box>
        <Button as="button" onClick={onToggle} size="sm" type="button" variant="beige">
          Show
        </Button>
      </Box>
    </Flex>
  );
});
