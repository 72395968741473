/* eslint-disable complexity */
import { Box, Flex } from '@chakra-ui/react';
import { useEvent } from '@ours/web-utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC, MouseEventHandler } from 'react';
import { memo } from 'react';

import { SideMenuItem } from './SideMenuItem';
import type { OnClose, SidebarMenuSchema } from './types';

export interface SideMenuTitleProps {
  item: SidebarMenuSchema;
  onClose: OnClose;
}

export const SideMenuTitle: FC<SideMenuTitleProps> = memo(({ item, onClose }) => {
  const { hidden, href, items, onClick, title } = item;
  const clickable = !!(href || onClick);
  const { pathname } = useRouter();
  const isActive = pathname === href;
  const onClickLocal: MouseEventHandler<any> = useEvent((e) => {
    onClose(e);
    if (onClick) {
      onClick();
    }
  });

  if (hidden) {
    return null;
  }

  return (
    <Box>
      <Box
        _hover={{
          cursor: clickable ? 'pointer' : undefined,
          textDecoration: clickable ? 'underline' : undefined,
        }}
        color={isActive ? 'brand.blue' : undefined}
        fontWeight="600"
        onClick={onClickLocal}
        pb="50"
        textStyle="xs"
      >
        {href ? <Link href={href}>{title}</Link> : title}
      </Box>

      {(items || []).filter((i) => !i.hidden).length > 0 ? (
        <Flex color="#444444" flexDir="column" gap="50" pl="150" textStyle="xs">
          {items?.map((item) => (
            <SideMenuItem item={item} key={item.text} onClose={onClose} />
          ))}
        </Flex>
      ) : null}
    </Box>
  );
});
