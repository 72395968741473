import { Flex } from '@chakra-ui/react';
import { GuideRoutes } from '@ours/utils';
import { BrandLogoColored } from '@ours/web-icons';
import { Button, useAppActions, useUserCan } from '@ours/web-utils';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { PiiModeSwitch } from './lib/PiiModeSwitch';
import { SideMenuTitle } from './lib/SideMenuTitle';
import type { OnClose, SidebarMenuSchema } from './lib/types';

export const SidebarMenu: FC<{ onClose: OnClose }> = memo(({ onClose }) => {
  const { isAdmin, isAdminCreator, isAdminGuideSupervisor, isAdminSupervisor } = useUserCan();
  const { onLogout } = useAppActions();

  const items = useMemo<SidebarMenuSchema[]>(
    () => [
      {
        href: GuideRoutes.Home,
        items: [
          {
            hidden: !isAdminGuideSupervisor,
            href: GuideRoutes.Couples,
            kind: 'link',
            text: 'Couples',
          },
          {
            hidden: !isAdminGuideSupervisor,
            href: GuideRoutes.EventsOverview,
            kind: 'link',
            text: 'Events overview',
          },
          {
            hidden: !isAdminGuideSupervisor,
            href: GuideRoutes.EventsRecent,
            kind: 'link',
            text: 'Recently booked events',
          },
        ],
        title: 'Dashboard',
      },
      {
        hidden: !isAdminCreator,
        href: GuideRoutes.Sessions,
        items: [
          { hidden: !isAdminCreator, href: GuideRoutes.Modules, kind: 'link', text: 'Modules' },
          {
            hidden: !isAdminCreator,
            href: GuideRoutes.Content,
            kind: 'link',
            text: 'Guide Content',
          },
        ],
        title: 'Sessions',
      },
      {
        href: GuideRoutes.Profile,
        items: [
          { href: GuideRoutes.ScheduleExceptions, kind: 'link', text: 'View schedule' },
          { href: GuideRoutes.SchedulePreview, kind: 'link', text: 'Schedule preview' },
        ],
        title: 'Profile',
      },
      {
        items: [
          { hidden: !isAdminSupervisor, href: GuideRoutes.Users, kind: 'link', text: 'Users' },
          {
            hidden: !isAdmin,
            href: GuideRoutes.Configuration,
            kind: 'link',
            text: 'Configuration',
          },
          { href: GuideRoutes.IdTranslator, kind: 'link', text: 'ID Translator' },
          { href: GuideRoutes.Haikus, kind: 'link', text: 'Haikus' },
          { href: GuideRoutes.DateFormatter, kind: 'link', text: 'Date Formatter' },
        ],
        title: 'Admin',
      },
    ],
    [isAdmin, isAdminCreator, isAdminGuideSupervisor, isAdminSupervisor]
  );

  return (
    <Flex flexDir="column" justifyContent="space-between" minH="100vh" px="300" py="320" w="full">
      <Flex flexDir="column">
        <Flex justifyContent="center" pb="300" w="full">
          <BrandLogoColored />
        </Flex>
        <Flex flexDir="column" gap="200" pb="400">
          {items.map((item) => (
            <SideMenuTitle item={item} key={item.title} onClose={onClose} />
          ))}
        </Flex>
        <Flex flexDir="column" gap="300" w="full">
          <PiiModeSwitch onClose={onClose} />
        </Flex>
      </Flex>

      <Button as="button" onClick={() => onLogout()} size="smFullW" type="button" variant="beige">
        Logout
      </Button>
    </Flex>
  );
});
