import { Box } from '@chakra-ui/react';
import type { IProgress } from '@ours/web-utils';
import type { FC } from 'react';
import { memo } from 'react';

export interface Props {
  upload: { key: string } & IProgress;
}

export const DisplayUploadProgress: FC<Props> = memo(({ upload }) => {
  return (
    <Box>
      {upload.friendlyName || upload.key} | {upload.state} | {upload.percentage}%
    </Box>
  );
});
